import React from 'react';
import { Flex, VStack } from '@chakra-ui/react';
import { LoginActivity } from './components/LoginActivity';
import { Permissions } from './components/Permissions';
import { UserDetails, UserRoleEnum } from '../../types';
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
} from 'react-query';
import { MainInfoSection } from './components/MainInfoSection';
import { OnboardingFormSection } from './components/OnboardingFormSection';
import { TimeLineSection } from './components/TimelineSection';

interface UserCardProps {
	user?: UserDetails;
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
	) => Promise<QueryObserverResult<any, unknown>>;
	dmsToTomCruzCount?: number | null;
}

export const MainInfo: React.FC<UserCardProps> = ({
	user,
	refetch,
	dmsToTomCruzCount,
}) => {
	return (
		<VStack align={'stretch'} spacing={'20px'}>
			<Flex
				direction={{
					base: 'column',
					md: 'row',
				}}
				gap={'20px'}>
				<VStack spacing={'20px'} flex={1} align={'stretch'}>
					<MainInfoSection
						id={user?.id}
						lastName={user?.lastName}
						firstName={user?.firstName}
						email={user?.email}
						roleId={user?.roleId}
						invitationDate={user?.invitationDate}
						phoneNumber={user?.phoneNumber}
						invitationResendDate={user?.invitationResendDate}
						documentLink={user?.documentLink}
						isDocumentCompleted={Boolean(user?.isDocumentCompleted)}
					/>
					{user?.roleId !== UserRoleEnum.Guest ? (
						<OnboardingFormSection
							cashAvailableToInvestId={
								user?.cashAvailableToInvestId || 0
							}
							soonLookingId={user?.soonLookingId || 0}
							realEstateExperienceId={
								user?.realEstateExperienceId || 0
							}
						/>
					) : null}
				</VStack>
				<VStack spacing={'20px'} flex={1} align={'stretch'} minW={0}>
					<LoginActivity
						lastLoginDate={user?.lastLoginDate}
						loginCount={user?.loginCount}
						roleId={user?.roleId}
						dmsToTomCruzCount={dmsToTomCruzCount}
					/>
					<TimeLineSection />
				</VStack>
			</Flex>
			{user && user?.roleId !== UserRoleEnum.Guest ? (
				<Permissions
					credentials={{
						firstName: user.firstName,
						lastName: user.lastName,
						email: user.email,
						id: user.id,
						status: user.status,
						isSuspended: user.isSuspended,
						role: user.roleId ? user.roleId : null,
						isPending: Boolean(user.signUpDate),
						permissionId: user.permissionGroupId
							? user?.permissionGroupId
							: null,
					}}
					refetch={refetch}
				/>
			) : null}
		</VStack>
	);
};
