/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useState } from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import { Guests, Main } from './Tabs';
import { useSearchParams } from 'react-router-dom';

const TABS = ['Main', 'Guests'];

export const Users: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 0);

	return (
		<Box bg={colors.background} p={{ base: '10px', md: '20px' }}>
			<Tabs
				position="relative"
				variant="unstyled"
				isLazy={true}
				index={Number(activeTab) || 0}
				onChange={index => {
					setActiveTab(index);
					setSearchParams({ tab: String(index) });
				}}>
				<TabList
					p={'10px'}
					bg={'white'}
					gap={'5px'}
					borderRadius={'10px'}
					display={'inline-flex'}>
					{TABS.map((tab, index) => (
						<Tab
							_selected={{
								bg: 'blueText',
								color: 'white',
								border: 'none',
								borderRadius: '8px',
							}}
							fontSize={'14px'}
							fontWeight={'500'}
							borderRadius={'8px'}
							w={'160px'}
							key={index}>
							{tab}
						</Tab>
					))}
				</TabList>
				<TabPanels>
					<TabPanel px={'0'}>
						<Main />
					</TabPanel>
					<TabPanel px={'0'}>
						<Guests />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};
