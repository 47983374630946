export interface IUser {
	status: string;
	signUpDate: string;
	lastName: string;
	isSuspended: boolean;
	email: string;
	firstName: string;
	id: number;
	roleId: number;
	permissionId: number;
	isAgreementSigned: boolean;
	invitationDate?: string;
	totalScore: number;
	riskLevel: number;
}

export interface GuestUser {
	id: number;
	email: string;
	firstName: string;
	lastName: string;
}

export enum UserRiskLevelEnum {
	NONE = 1,
	LOW = 2,
	MEDIUM = 3,
	HIGH = 4,
}
